@keyframes recording {
  from {
    color: red;
  }
  to {
    color: black;
  }
}

.recording {
  color: black;
  animation: recording 1s ease-in-out infinite;
}

.recordingStopped {
  color: black;
}
