.rsw-editor {
  width: 100%;
  max-width: 700px;
  overflow-wrap: break-word;
}

@media screen and (max-width: 400px) {
  .rsw-editor {
    width: 300px;
  }
}
