.uploadButton {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid #333;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  padding: 1.1em 2.8em;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 6px;
  color: #333;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 250ms ease-in-out;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadButton:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 100%;
  background: #ccc;
  z-index: -1;
  transition: width 250ms ease-in-out;
}

.inputFile {
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.inputFile:focus {
  outline: none;
}
